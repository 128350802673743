import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useUser, useNotify, useModal, format } from '@moved/services';
import { Notebox, Button } from '@moved/ui';

import { claimAccount } from '../actions';

import { ClaimAccountVerificationModal } from './ClaimAccountVerificationModal';

import CSS from '../styles/ClaimAccountSummary.module.scss';

export const ClaimAccountSummary = ({ code, claim, title, subtitle, onComplete }) => {

  const notify = useNotify();
  const modal = useModal();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const { user, logout } = useUser();

  const isUserTenantMatch = user?.email === claim?.email;

  const fields = [
    { label: 'First name', value: claim?.firstname },
    { label: 'Last name', value: claim?.lastname },
    { label: 'Email', value: claim?.email },
    claim?.unit && { label: 'Unit number', value: claim.unit },
    claim?.lease_date && { label: `Lease ${claim?.move_step_type === 'move-in' ? 'start' : 'end'} date`, value: format.date(claim.lease_date) },
  ].filter(v=>v);

  const verify = () => {
    modal.openLegacy(<ClaimAccountVerificationModal claim={claim} />, {
      onClose: (result) => {
        if(result === true) submit();
      },
    });
  };

  const submit = () => {
    if(pending) return;
    setPending(true);
    dispatch(claimAccount(claim?.building_id, { claim_token: code }))
      .then((response) => {
        onComplete?.(response);
      })
      .catch(err => {
        setPending(false);
        notify.error(format.error(err));
      });
  };

  return (
    <>
      <div className={CSS.content}>
        <div className={CSS.flex_wrapper}>
          <h1 className={CSS.title}>{title}</h1>
          <h3 className={CSS.subtitle}>{subtitle}</h3>
          { !isUserTenantMatch && (
            <Notebox
              icon={{ library:'code', symbol:'Warning-2' }}
              color='yellow'
              title={(<>You are currently logged in as <strong>{ user.fullname } ({user.email})</strong></>)}
              className='marginTop-24 marginBottom-24'
            />
          )}

          <div className={CSS.card_wrapper}>
            { fields.map(field => (
              <div className={CSS.field} key={field.label}>
                <label>{ field.label }</label>
                <div>{ field.value }</div>
              </div>
            ))}
          </div>
          <p className='mt-25'>
            Please confirm if you are attempting to link this { claim?.move_step_type }{' '}
            to your existing account, or if you want to create a new account for{' '}
            {claim?.firstname} {claim?.lastname}.
          </p>
        </div>
      </div>
      <div className={CSS.footer}>
        <Button
          text={`Link account under ${user.fullname}`}
          size='default'
          color={isUserTenantMatch ? 'primary' : 'secondary'}
          tabIndex="0"
          disabled={pending}
          onClick={verify}
          className={classNames({[CSS.reorder_last]: !isUserTenantMatch})}
          icon={{
            symbol:'Attachment#2',
            library:'general'
          }}
        />
        <div className={classNames('labelS contentSecondary',CSS.divider)}>or</div>
        <Button
          text={isUserTenantMatch ? `Register a new email address` : `Create new account for ${claim?.firstname} ${claim?.lastname}`}
          size='default'
          color={isUserTenantMatch ? 'secondary' : 'primary'}
          tabIndex="10"
          disabled={pending}
          onClick={() => logout()}
          className={classNames({[CSS.reorder_first]: !isUserTenantMatch})}
          icon={{
            symbol:'Crew-2',
            library:'people',
          }}
        />
      </div>
    </>
  );

};
