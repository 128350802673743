import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_PET_INSURANCE_LINK_PET',
  request: (id,petId) => request.post(`/residents/personal-tasks/${id}/pets/${petId}/link`,{})
    .then(r => r.data.data.pet),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      availablePets: {
        ...state.availablePets,
        [id]: {
          ...state.availablePets?.[id],
          pets: (state.availablePets?.[id]?.pets || []).map(pet => ({
            ...pet,
            is_selected: pet.id === response.id,
          })),
        },
      },
    }),
  }
};

export const {
  reducer,
  action: linkPet,
  usePending: useLinkPetPending,
} = generate.redux(creator);

export const initialState = {};
