import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useOptionalControl } from '@moved/services';

import { Text } from '../Text';

export const NumberValidation = Yup.string().test(
  'length',
  'Must be a valid US phone number',
  (value='') => (!value || value.length === (value[0] === '1' ? 11 : 10))
);

const sanitizeNumber = (value='', allowDecimal, allowNegative) => {
  const regex = (
    allowDecimal && allowNegative ? /[^0-9.-]|(?!^)-|(?<=\..*)\./g :  // Allow digits, one '.', and one '-' at the start
    allowDecimal ? /[^0-9.]|(?<=\..*)\./g :                           // Allow digits and one '.'
    allowNegative ? /[^0-9-]|(?!^)-/g :                               // Allow digits and one '-' at the start
    /\D/g                                                             // Allow only digits
  );
  return String(value).replace(regex, '');
};

export const Number = ({
  type='number',
  value,
  isControlled,
  name,
  onChange,
  min,
  max,
  allowDecimal,
  allowNegative,
  ...props
}) => {
  const [number, setNumber] = useOptionalControl(
    sanitizeNumber(value, allowDecimal, allowNegative),
    isControlled
  );

  const handleChange = ({ [name]:newValue }) => {
    const sanitizedValue = sanitizeNumber(newValue, allowDecimal, allowNegative);
    if(sanitizedValue === number) return; // only trigger if changed
    if(min && (parseFloat(sanitizedValue) < min)) return;
    if(max && (parseFloat(sanitizedValue) > max)) return;
    setNumber(sanitizedValue);
    onChange?.({[name]:sanitizedValue});
  };

  return (
    <Text
      { ...props }
      name={name}
      value={number}
      isControlled={true}
      onChange={handleChange}
      type='text' // needs to be text to avoid stupid brower scientific notation built-in
      min={min}
      max={max}
    />
  );
};

// Inherits from TEXT, not sure how to keep it in sync DRYly
Number.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Type to use for the form input */
  type: PropTypes.string,
  /** Text value to use for this input (only initial value if not controlled) */
  value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** (optional) Second line of text */
  hint: PropTypes.string,
  /** (Optional) Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** (Optional) Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** class name to add to the input container */
  className: PropTypes.string,
  /** error message to display for this input */
  error: PropTypes.string,
};
