import React from 'react';

import { CompleteCard, FieldList } from '@moved/ui';
import { format } from '@moved/services';

import { Screen } from '../../../tasks/types/shared';
import { useAvailablePets } from './actions/getAvailablePets';

export const CompleteInsuranceScreen = ({ screen, personalTask, purchasedPolicy }) => {

  const { pets: availablePets } = useAvailablePets(personalTask?.id) ?? {};
  const selectedPet = availablePets?.find(({ is_selected }) => is_selected);
  const attributes = [
    {
      label: 'Covered pet',
      value: [selectedPet?.name, selectedPet?.pet_type, selectedPet?.breed].filter(Boolean).join(', '),
      icon: { symbol: selectedPet?.pet_type, library: 'animals' },
    },
    {
      label: 'Provider',
      value: purchasedPolicy?.insurance_provider,
      icon: { symbol: 'Home', library: 'home' },
    },
    {
      label: 'Policy number',
      value: purchasedPolicy?.policy_number,
      icon: { symbol: 'File', library: 'files' },
    },
    {
      label: 'Coverage start date',
      value: format.date(purchasedPolicy?.coverage_start),
      icon: { symbol: 'Date', library: 'code' },
    },
    {
      label: 'Coverage end date',
      value: format.date(purchasedPolicy?.coverage_end),
      icon: { symbol: 'Date-to', library: 'code' },
    },
    purchasedPolicy?.total_premium_in_cents && {
      label: 'Total premium',
      value: format.currency(purchasedPolicy?.total_premium_in_cents),
      icon: { symbol: 'Shield-check', library: 'general' },
    },
    purchasedPolicy?.down_payment_in_cents && {
      label: 'Deposit paid',
      value: format.currency(purchasedPolicy?.down_payment_in_cents),
      icon: { symbol: 'Dollar', library: 'shopping' },
    },
    purchasedPolicy?.files?.length > 0 && {
      label: 'Policy documents',
      value: (
        <div className='stackVertical gap-4'>
          { purchasedPolicy?.files?.map(file => (
            <a href={file.url} target='_blank' rel='noopener noreferrer' key={file.document_name}>
              { file.document_name }
            </a>
          ))}
        </div>
      ),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
  ].filter(Boolean);

  return (
    <Screen screen={screen}>
      <Screen.Content>
        <CompleteCard
          status='approved'
          title={`${selectedPet?.name} is covered!`}
          flavor='The details of your new policy are below.'
        >
          <FieldList list={attributes} />
        </CompleteCard>
      </Screen.Content>
    </Screen>
  );
};
