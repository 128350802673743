import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { merge } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { Button, EmptyContent, Card, Notebox } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { Snippet } from '../../../../snippets';
import { Screen } from '../../shared';

import CSS from './styles/Payment.module.scss';

export const Payment = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const pending = taskDefinition.selectors.useCreatePayMoveInCostsRequestPending();
  const taskDetails = taskDefinition.selectors.useTaskable(id);
  // helpers
  const { content, title, subtitle, cta_text } = taskDetails?.instruction_content ?? {};
  const costsByCategory = taskDetails?.costs_by_category ?? [];
  const payments = taskDetails?.payments ?? [];
  const totalDue = taskDetails?.total_amount_owed ?? 0;
  const remainingBalance = taskDetails?.remaining_unpaid_amount ?? 0;
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);

  const submit = () => {
    // this task allows back navigation to this screen any time, so if there is already
    // an active request that is not rejected, just navigate forward
    if(activeRequest && activeRequest?.status !== 'rejected') return nextScreen();
    // otherwise do the usual create and navigate forward
    dispatch(taskDefinition.actions.createRequest(id))
      .then(nextScreen)
      .catch(error => notify.error(format.error(error)));
  };

  // filter empty categories
  const categoriesToDisplay = costsByCategory.filter(({ costs }) => (costs ?? []).length);



  return (
    <Screen taskDefinition={taskDefinition} screen={merge(screen,{ title, subtitle })}>
      <Screen.Title />
      <Screen.Content
        noteboxes={categoriesToDisplay.length === 0 && (
          // if no costs yet, show a notebox
          <Notebox
            title='Move-in costs pending'
            body={`The exact move-in costs owed varies per unit, date of lease start,
              and several other factors. Please check back later, or reach out to your
              leasing agent for details on what you owe.`}
            color='yellow'
            icon={{ library: 'code', symbol: 'Warning-2' }}
          />
        )}
      >
        <div className={CSS.content_wrapper}>

          <div className={CSS.instructions}>

            <div className='headingS mb-20'>Please follow the instructions below</div>

            { content ? (
              <ResidentContentRenderer content={content} />
            ) : (
              <EmptyContent/>
            )}

          </div>

          { (payments.length > 0 || remainingBalance > 0 || categoriesToDisplay.length > 0) && (
            <div className={classNames('stackVertical gap-16',CSS.bill)}>
              { categoriesToDisplay.length > 0 && (
                <Card>
                  { categoriesToDisplay.map(({category,costs}) => (
                    <div key={category.label}>
                      { costs.length === 1 ? ( // single entry categories get an inline title and cost
                        <div className={classNames(CSS.line_item,'marginBottom-8')}>
                          <div className='stackVertical'>
                            <span className='headingS contentPrimary'>{ costs.at(0)?.description }</span>
                            { category.subtitle && (
                              <span className='labelS contentSecondary paddingTop-2'>({ category.subtitle })</span>
                            )}
                          </div>
                          <div className='labelM contentPrimary'>{format.currency(costs.at(0)?.amount)}</div>
                        </div>
                      ) : ( // multi line categories get a category title and divider if not last category
                        <>
                          <div className='stackVertical marginBottom-4'>
                            <div className='headingS contentPrimary'>{ category.display_name }</div>
                            { category.subtitle && (
                              <div className='labelS contentSecondary'>({ category.subtitle })</div>
                            )}
                          </div>
                          { costs.map(cost => (
                            <div className={CSS.line_item} key={cost.id}>
                              <div className='labelM contentSecondary paddingLeft-8'>{cost.description}</div>
                              <div className='labelM contentPrimary'>{format.currency(cost.amount)}</div>
                            </div>
                          ))}
                        </>
                      )}
                      <div className={CSS.divider}/>
                    </div>
                  ))}

                  <div className={CSS.line_item}>
                    <div className='labelM contentSecondary'>Total due at move-in</div>
                    <div className='labelM contentPrimary'>{format.currency(totalDue)}</div>
                  </div>
                </Card>
              )}

              { (payments.length > 0 || remainingBalance > 0) && (
                <Card>
                  { payments.length > 0 && (
                    <>
                      <div className='headingS mb-10'>Payments</div>
                      { payments.map(item => (
                        <div className={CSS.line_item} key={item.id}>
                          <div className='labelM contentSecondary'>{item.description} ({format.date(item.transaction_date,'date')})</div>
                          <div className='labelM contentPrimary'>{format.currency(item.amount)}</div>
                        </div>
                      ))}
                      <div className={CSS.divider}/>
                    </>
                  )}

                  <div className={CSS.line_item}>
                    <div className='headingS'>Remaining due</div>
                    <div className='headingS'>{format.currency(remainingBalance)}</div>
                  </div>

                  <div className='labelS contentSecondary'>
                    <Snippet
                      tag='tasks.pay-move-in-costs.payment.remaining-balance-instructions'
                      description='Remaining balance instructions'
                    >
                      Please pay remaining move-in costs prior to move-in day.
                    </Snippet>
                  </div>

                </Card>
              )}
            </div>
          )}

        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text={cta_text || 'Confirm payment'}
          size='large'
          onClick={submit}
          disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
}
