import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { Drawer } from '@moved/ui';

import { useMove } from '../../../moves/actions/getMove';
import { useTask } from '../../actions/getTask';
import { getInsurancePurchaseIntent, useInsurancePurchaseIntent } from '../purchaseRentersInsurance/actions/get';
import { PurchasePetInsuranceFlow } from './PurchasePetInsuranceFlow';

export const PurchasePetInsuranceDrawer = ({ taskId, moveId }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const personalTask = useTask(taskId);
  const move = useMove(moveId);

  const purchaseIntent = useInsurancePurchaseIntent(personalTask?.task_details_id);

  useEffect(() => {
    if(personalTask?.task_details_id == null) return;
    dispatch(getInsurancePurchaseIntent(personalTask.task_details_id))
        .catch(err => notify.error(format.error(err)));
  }, [personalTask, dispatch, notify]);

  return (
    <Drawer>
      <PurchasePetInsuranceFlow
        personalTask={personalTask}
        purchaseIntent={purchaseIntent}
        move={move}
        dashboardRoute={`/moves/${moveId}/personal-tasks`}
      />
    </Drawer>
  );
}
