import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';
import { BouncingDots } from '@moved/ui';

import { TaskFlowLayout } from '../../../tasks/components/TaskFlowLayout';
import { TaskScreenTransition } from '../../../tasks/components/TaskScreenTransition';
import { ScreenLoading } from '../../../tasks/types/shared';

import { getAvailablePets, useAvailablePets } from './actions/getAvailablePets';

import { PurchasePetInsuranceFlowTrain } from './PurchasePetInsuranceFlowTrain';
import { AddNewPetScreen } from './AddNewPetScreen';
import { AvailablePetsScreen } from './AvailablePetsScreen';
import { ReinInsuranceScreen } from './ReinInsuranceScreen';
import { CompleteInsuranceScreen } from './CompleteInsuranceScreen';

// Main Taskflow Component
export const PurchasePetInsuranceFlow = ({
  personalTask,
  purchaseIntent,
  move,
  dashboardRoute,
}) => {
  const drawer = useDrawer();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [createNewPet, setCreateNewPet] = useState();

  const {
    pets: availablePets = [],
  } = useAvailablePets(personalTask?.id) ?? {};
  const selectedPet = availablePets.find(({ is_selected }) => is_selected);

  const { rein_reference_id: reinId } = purchaseIntent ?? {};
  const purchasedPolicy = move.insurance_purchases.find(({ rein_reference_id: id }) => id === reinId);

  useEffect(() => {
    if(!personalTask.is_embedded && !purchasedPolicy) {
      notify.error('Your address has changed and our insurance partner does not support policies in your state.');
      drawer.close();
    }
  },[personalTask, purchasedPolicy, notify]); // eslint-disable-line

  useEffect(() => {
    dispatch(getAvailablePets(personalTask?.id))
      .then(({ pets }) => pets?.length === 0 && setCreateNewPet(true))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setLoading(false));
  }, [personalTask?.id, dispatch, notify]);

  const screens = [
    availablePets?.length > 0 && {
      slug: 'pets',
      label: 'Select a pet',
      component: AvailablePetsScreen,
      title: 'Select the pet to insure',
      subtitle: 'We will prefill your pet’s information to save you time.',
      isComplete: selectedPet != null || createNewPet === true,
      maxWidth: 'none',
    },
    (availablePets?.length === 0 || createNewPet === true) && {
      slug: 'add-pet',
      label: availablePets?.length > 0 ? 'Add a new pet' : 'Add a pet',
      component: AddNewPetScreen,
      title: 'Tell us more about your pet.',
      subtitle: 'Coverage and premiums will depend on your pet’s breed, age, and health.',
      isComplete: selectedPet != null,
      backRestricted: true,
    },
    {
      slug: 'rein-insurance',
      label: 'Shop for insurance',
      component: ReinInsuranceScreen,
      title: 'Purchase renters insurance',
      isComplete: purchasedPolicy != null,
      backRestricted: true,
    },
    {
      slug: 'summary',
      label: 'Summary',
      component: CompleteInsuranceScreen,
      title: 'Summary',
      isComplete: purchasedPolicy != null,
      backRestricted: true,
    },
  ].filter(Boolean);

  const activeScreenIndex = screens.findIndex(screen => !screen.isComplete || screen.slug === 'summary');
  const activeScreen = screens[activeScreenIndex];

  const previousScreen = () => drawer.close();

  return (
    <TaskFlowLayout origin={dashboardRoute}>

      <TaskFlowLayout.Sidebar>
        <PurchasePetInsuranceFlowTrain
          screens={screens}
          activeScreenIndex={activeScreenIndex}
          origin={dashboardRoute}
        />
      </TaskFlowLayout.Sidebar>

      <TaskFlowLayout.Content previousScreen={previousScreen}>

        { loading ? (
          <ScreenLoading
            message={
              <span>
                Retrieving personalized quotes for your new address<BouncingDots />
              </span>
            }
          />
        ) : (
          <TaskScreenTransition
            screen={activeScreen}
            index={activeScreenIndex}
            personalTask={personalTask}
            purchaseIntent={purchaseIntent}
            purchasedPolicy={purchasedPolicy}
            setCreateNewPet={setCreateNewPet}
            move={move}
            origin={dashboardRoute}
          />
        )}

      </TaskFlowLayout.Content>

    </TaskFlowLayout>
  );
};
