import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_PET_INSURANCE_GET_PET_BREEDS',
  request: () => request.get(`/pet-breeds`)
    .then(r => r.data.data.pet_breeds),
  selector: (state) => state?.petBreeds,
  cache: true,
  reducers: {
    success: (state, { response }) => ({
      ...state,
      petBreeds: response,
    }),
  }
};

export const {
  reducer,
  action: getPetBreeds,
  useResource: usePetBreeds,
  usePending: usePetBreedsPending,
} = generate.redux(creator);

export const initialState = {
  petBreeds: {},
};
