import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useMobile } from '@moved/services';
import { Icon } from '@moved/ui';

import desktopCSS from '../../../tasks/components/styles/TrainDesktop.module.scss';
import mobileCSS from '../../../tasks/components/styles/TrainMobile.module.scss';

// Main Taskflow Component
export const PurchasePetInsuranceFlowTrain = ({ screens, activeScreenIndex, origin }) => {
  const isMobile = useMobile();

  const percentageComplete = ((activeScreenIndex+1)/screens?.length)*100;
  const activeProgressBarHeight = ((activeScreenIndex+1) * 76) - 16;
  return isMobile ? (
    <div className={mobileCSS.train}>
      <div className={mobileCSS.station}>
        <div
          className={classNames(mobileCSS.rail, { [mobileCSS.complete]: (percentageComplete>=100) })}
          style={{ width: `${percentageComplete}%`, }}
        />
      </div>
      <Link to={origin} className={mobileCSS.nav}>
        <Icon symbol='Close' library='navigation' size='24px' />
      </Link>
    </div>
  ) : (
    <div className={classNames('stackVertical justify-center items-center',desktopCSS.train)}>
      <div className={desktopCSS.track}>

        { screens.map((screen, index) => (
          <div
            key={screen.slug}
            className={classNames(desktopCSS.stop, screen.isComplete ? desktopCSS.completed : desktopCSS.current)}
          >
            <span className={desktopCSS.number}>{ index+1 }</span>
            <span className={desktopCSS.text}>{ screen.label }</span>
          </div>
        ))}

        <div className={desktopCSS.lines} style={{ height: activeProgressBarHeight }}>
          { screens.map((screen, index) => (
            <span
              key={screen.slug}
              className={classNames(desktopCSS.circle, { [desktopCSS.active_circle]: index >= activeScreenIndex })}
            />
          ))}
        </div>

      </div>
    </div>
  );
};
