import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_INSURANCE_GET_PURCHASE_INTENT',
  request: (id) => request.get(`/residents/insurance-purchase-intents/${id}`).then(r => r.data.data.insurance_purchase_intent),
  selector: (state, id) => state?.insurancePurchaseIntents?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      insurancePurchaseIntents: {
        ...state.insurancePurchaseIntents,
        [id]: response,
      }
    }),
  }
};

export const {
  action: getInsurancePurchaseIntent,
  reducer,
  useResource: useInsurancePurchaseIntent,
  usePending: useInsurancePurchaseIntentPending,
} = generate.redux(creator);

export const initialState = {
  insurancePurchaseIntents: {},
};
