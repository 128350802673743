import * as createPet from './createPet';
import * as getAvailablePets from './getAvailablePets';
import * as getPetBreeds from './getPetBreeds';
import * as linkPet from './linkPet';

export const reducers = [
  createPet.reducer,
  getAvailablePets.reducer,
  getPetBreeds.reducer,
  linkPet.reducer,
];

export const initialState = {
  ...createPet.initialState,
  ...getAvailablePets.initialState,
  ...getPetBreeds.initialState,
  ...linkPet.initialState,
};
