import classNames from 'classnames';

import { Card, Icon, Badge, Tooltip } from '@moved/ui';

import { petAgeLimits } from './helpers/petAgeLimits';

import CSS from './PetCard.module.scss';

export const PetCard = ({ pet, isSelected, onSelect }) => {
  const petAgeLimit = petAgeLimits[pet?.pet_type?.toLowerCase()];
  const isEligible = pet.age_years < petAgeLimit;
  return (
    <Card
      className={classNames(CSS.petCard,{[CSS.selected]:isSelected,[CSS.clickable]:isEligible})}
      onClick={() => isEligible && onSelect()}
    >
      <Icon
        library='code'
        symbol='Radio-on'
        className={CSS.radio}
      />
      <div className={CSS.circle} />
      <div className='stackVertical gap-4 items-center'>
        <h3 className={classNames('headingL', isEligible ? 'contentPrimary' : 'contentSecondary')}>
          { pet.name }
        </h3>
        { isEligible ? (
          <h2 className='labelM contentSecondary'>{pet.breed}</h2>
        ) : (
          <Tooltip
            tooltip={`This pet is beyond the age limit (${petAgeLimit} years) for insurance eligibility.`}
          >
            <Badge
              text='Ineligible to insure'
              type='error'
              icon={{ library: 'code', symbol: 'Question-circle'}}
              className={CSS.ineligible}
            />
          </Tooltip>
        )}
      </div>
      <div
        className={CSS.petImage}
        style={{ backgroundImage: pet?.photo_url ? `url(${pet.photo_url})` : 'none' }}
      >
        {pet?.photo_url == null && (
          <Icon
            symbol={pet.pet_type}
            library='animals'
            size='100%'
            className={CSS.petIcon}
          />
        )}
      </div>
    </Card>
  );
};
