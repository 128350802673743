import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Notebox, Button } from '@moved/ui';

import {
  DayPicker,
  DayPickerItem,
  DayTooltips,
  DependentTaskNoteboxes,
  TimePicker,
  TimePickerItem,
  Screen,
} from '../../shared';

import { KeyPickupLocationNotebox } from './KeyPickupLocationNotebox';

import CSS from './styles/KeySelectDates.module.scss';

export const KeySelectDates = ({ screen, nextScreen, taskDetails, taskDefinition, taskSummary }) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const keyPickupTask = taskDetails;

  const submitRequestPending = taskDefinition.selectors.useSubmitRequestPending();
  const createRequestPending = taskDefinition.selectors.useCreateRequestPending();
  const pendingSubmit = submitRequestPending || createRequestPending;

  const calendars = taskDefinition.selectors.useKeyCalendar();
  const calPending = taskDefinition.selectors.useKeyCalendarPending();

  const [keyPickupDate, setKeyPickupDate] = useState({});
  const [keyPickupTime, setKeyPickupTime] = useState('');

  const activeRequest = taskDefinition.helpers.getActiveRequest(keyPickupTask);
  const existingAppointments = taskDefinition.helpers.getArtifacts(taskSummary);
  const activeAppointment = existingAppointments.length > 0 && existingAppointments[0];

  const calendar = calendars && calendars.length ? calendars.find(calendar => calendar.id === (get(activeRequest,'building_calendar.id') || calendars[0].id)) : {};
  const availabilityCalendar = get(calendar, 'availability', []);
  const earliestKeyAvailability = get(keyPickupTask,'earliest_key_pickup_datetime')
    ? moment(get(keyPickupTask,'earliest_key_pickup_datetime'))
    : moment();

  // Load the initial calendar data
  useEffect(() => {
    dispatch(taskDefinition.actions.getCalendarOptions(id))
      .catch(error => notify.error('Error loading calendar data'));

    if(!activeRequest) {
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ keyPickupTask.current_tenant_key.id],
          'building_calendar_id': calendar.id
        })))
        .catch(error => notify.error('Error creating calendar request'));
    }
  },[]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(calendar)) setKeyPickupDate(getInitialSelectedDate());
  },[calendar]); // eslint-disable-line

  // Day picker helpers
  const daysList = availabilityCalendar
    // filter out all days before availability (but keep one day before for design layout)
    .filter(day => !earliestKeyAvailability.clone().subtract(1,'d').isAfter(day.date,'day'))
    .map(day => ({
      id: day.date,
      isSelected: keyPickupDate.date === day.date,
      isDisabled: earliestKeyAvailability.isAfter(day.date,'day'),
      availability: day,
      content: (<>
        <DayTooltips
          date={day.date}
          earliestDate={keyPickupTask?.earliest_key_pickup_datetime}
          leaseStartDate={keyPickupTask?.lease?.start_date}
          currentAppointmentDate={keyPickupTask?.appointment?.pickup_date}
          calendar={calendar}
        />
        <DayPickerItem
          day={day}
        />
      </>),
    }));

  const updateKeyPickupDate = date => {
    if(date.isDisabled) return;
    setKeyPickupDate(date.availability);
    if(!date.availability.timeslots.includes(keyPickupTime)) setKeyPickupTime('');
  }

  // Time picker helpers
  const isSlotDisabled = slot => {
    const slotStart = `${keyPickupDate.date}T${slot.start}`;
    return !slot.is_available || earliestKeyAvailability.isAfter(slotStart);
  };
  const timesList = get(keyPickupDate, 'timeslots', []).map((time) => ({
    id: time.start,
    isSelected: time === keyPickupTime,
    isDisabled: isSlotDisabled(time),
    slot: time,
    content: (
      <TimePickerItem
        time={format.date(`${keyPickupDate.date}T${time.start}`,'h:mmA')}
        reserved={!time.is_available}
      />
    ),
  }));

  const getInitialSelectedDate = () => {
    if(!availabilityCalendar.length) return {};
    let selection;
    // if taskable already has a date selected, reselect it
    if (keyPickupTask.date) selection = availabilityCalendar.find(day => day.date === keyPickupTask.date);
    // if no previous selection attempt to use earliest pickup date
    if (!selection && get(keyPickupTask, 'earliest_key_pickup_datetime')) {
      selection = availabilityCalendar.find(day => moment(get(keyPickupTask, 'earliest_key_pickup_datetime')).isSame(day.date,'day'));
    }
    // fallback options is to select the first available date
    if(!selection) selection = availabilityCalendar.find(day => day.is_available !== false);
    return selection;
  };

  // Check if form submission is disabled
  const isDisabled = () => {
    if (calPending || pendingSubmit) return true;
    else if (calendar && calendar.booking_type === 'all-day') return isEmpty(keyPickupDate);
    else if (isEmpty(keyPickupDate) || keyPickupTime === '') return true;
  }

  const updateKeyPickupTime = slot => {
    if(!isSlotDisabled(slot)) setKeyPickupTime(slot);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(pendingSubmit) return null;

    const data = {
      pickup_date: keyPickupDate.date,
    }

    if(keyPickupTime !== '') {
      data.pickup_time = format.date(`${keyPickupDate.date}T${keyPickupTime.start}`,'h:mmA');
    }

    dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data))
      .then(resp => dispatch(taskDefinition.actions.submitRequest(get(resp,'requests[0].id'))))
      .then(nextScreen)
      .catch(error => notify.error( get(error, 'response.data.message') ));
  };

  const cancelReschedule = () => {
    return dispatch(taskDefinition.actions.cancelRequest(activeRequest.id))
      .then(nextScreen)
      .catch(error => {
        notify.error(format.error(error));
      });
  };


  const lastDate = get(availabilityCalendar[availabilityCalendar.length - 1],'date');

  return (
    <Screen screen={screen} taskDefinition={taskDefinition}>
      <Screen.Title />
      <Screen.Content
        noteboxes={(
          <>
            <DependentTaskNoteboxes taskDetails={taskDetails} activeMoveStep={activeMoveStep} origin={origin} />
            { activeAppointment && (
              <Notebox
                title={`Current appointment: ${activeAppointment.pickup_time ?? 'All day'}, ${format.date(activeAppointment.pickup_date, 'dddd MM/DD')}`}
                body='If there is no better time available for reschedule, you can keep this appointment.'
                color='blue'
                icon={{ library:'code', symbol:'Info-circle' }}
                actions={(
                  <Button color='secondary' text='Keep this appointment' onClick={cancelReschedule} />
                )}
              />
            )}
            <KeyPickupLocationNotebox request={activeRequest} />
          </>
        )}
      >
        <section className={CSS.picker_section}>
          {(daysList.length >= 1 && !(daysList.length === 1 && daysList[0].isDisabled)) ? (
            <div className={CSS.picker_container}>
              {
                calPending || isEmpty(calendar) || !activeRequest ? (
                  <AtomSpinner/>
                ) : (
                  <>
                    <DayPicker days={daysList} onSelect={updateKeyPickupDate} />
                    { calendar && calendar.booking_type === 'appointment-slot' &&
                      <TimePicker times={timesList} onSelect={(time) => updateKeyPickupTime(time.slot)} />
                    }
                  </>
                )
              }
            </div>
          ) : (
            <Notebox
              title={(lastDate ?
                `Appointments currently available up until ${format.date(lastDate)}.` :
                `There is currently no calendar configured for this property.`
              )}
              body='Please check back in a few days to schedule your upcoming appointment.'
              color={lastDate ? 'blue' : 'yellow'}
              icon={lastDate ? { symbol:'Info' } : { library:'code', symbol:'Warning-2' }}
            />
          )}
        </section>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          onClick={handleSubmit}
          disabled={isDisabled()}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  )
};
