import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_INSURANCE_SUBMIT_PURCHASE_INTENT',
  request: (id, data={}) => request.post(`/residents/insurance-purchase-intents/${id}/submit`, data).then(r => r.data.data.insurance_purchase),
};

export const {
  action: submitInsurancePurchaseIntent,
  reducer,
  usePending: useSubmitInsurancePurchaseIntentPending,
} = generate.redux(creator);

export const initialState = {};
