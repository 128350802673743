import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import CSS from './SlideToggle.module.scss';

export const SlideToggle = ({
  name,
  options,
  value, // active
  isControlled,
  onChange, // callback (change in format)
  label,
  hint,
  size='small',
  disabled,
  error,
  className,
}) => {
  const [active, setActive] = useOptionalControl(value, isControlled);

  const pillRef = useRef();
  const activeRef = useRef();

  useEffect(() => {
    if(active != null && activeRef.current) {
      pillRef.current.style.left = activeRef.current.offsetLeft + 'px';
      pillRef.current.style.width = activeRef.current.offsetWidth + 'px';
    }
  }); // call on every render

  const handleSelect = (newSelection) => {
    if(disabled || newSelection.value === active) return;
    setActive(newSelection.value);
    onChange?.({[name]:newSelection.value});
  };

  return (
    <div className={classNames('stackVertical gap-8', className)}>
      { label && (
        <label htmlFor={name} className='labelS contentSecondary'>{ label }</label>
      )}
      <div className={classNames(CSS.wrapper, CSS[size], {[CSS.disabled]: disabled })}>
        { options.map((option) => (
          <div
            key={option.value}
            id={`${name}-${option.value}`}
            ref={active === option.value ? activeRef : null}
            className={classNames(CSS.option, option.className, {[CSS.active]: active === option.value })}
            onClick={() => handleSelect(option)}
          >
            { option.label }
          </div>
        ))}
        <div className={CSS.pill} ref={pillRef} />
      </div>
      { error ? (
        <div className='labelS contentError'>{ error }</div>
      ) : hint && (
        <div className={classNames('labelS', disabled ? 'contentTertiary' : 'contentSecondary')}>{ hint }</div>
      )}
    </div>
  );
};

SlideToggle.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** List of options for individual radios in this group */
  options: PropTypes.arrayOf(PropTypes.shape({
    /** Value to use for this input option */
    value: PropTypes.any.isRequired,
    /** Label text for the input */
    label: PropTypes.node,
    /** (optional) class name to add to the option */
    className: PropTypes.string,
  })),
  /** Size variant to use */
  size: PropTypes.oneOf(['small','large']),
  /** Selected value (only initial value if not controlled) (match the value of available option) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Label text for the input */
  label: PropTypes.node,
  /** Second line of text */
  hint: PropTypes.string,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Error message for the input */
  error: PropTypes.string,
  /** (optional) class name to add to the wrapper */
  className: PropTypes.string,
};
