import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { Notebox } from '@moved/ui';

export const LastMinuteNotice = ({ moverBooking }) => {
  const requestedDate = get(moverBooking,'requested_pickup_date');
  const deadlineToBook = moment(requestedDate).subtract(1,'d').set({ h: 14 });
  const noticeVisibleDate = moment(requestedDate).subtract(3,'d');
  const isNoticeVisible = noticeVisibleDate.isBefore();

  if(!isNoticeVisible) return null;
  return (
    <Notebox
      title={`Don't wait!`}
      body={(
        <p>
          Your requested move date is quickly approaching. To secure your booking
          you must confirm your payment method by <strong>{ deadlineToBook.format('ha on MM/DD/YYYY') }</strong>.
        </p>
      )}
      color='yellow'
      icon={{ library:'code', symbol:'Warning-2' }}
      className='marginBottom-24'
    />
  );
};
