import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_PET_TYPES',
  request: () => request.get(`/pet-types`).then(r => r.data.data.pet_types),
  selector: (state) => state.petTypes,
  cache: true,
  reducers: {
    success: (state, { response: types }) => ({
      ...state,
      petTypes: types,
    }),
  },
};

export const {
  reducer,
  action: getPetTypes,
  useResource: usePetTypes,
  usePending: usePetTypesPending,
} = generate.redux(creator);

export const initialState = {
  petTypes: [],
};
