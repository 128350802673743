import { Link } from 'react-router-dom';

import { format } from '@moved/services';
import { Notebox, Button } from '@moved/ui';

export const DependentTaskNoteboxes = ({ taskDetails, activeMoveStep, origin }) => {
  return taskDetails?.dependent_building_tasks?.length > 0 && (
    <Notebox
      color='yellow'
      icon={{ library:'code', symbol:'Info-circle' }}
      title='Please note!'
      body={
        <div className='stackVertical gap-12'>
          { taskDetails?.dependent_building_tasks?.map(dependentTask => (
            <div key={dependentTask.building_move_task_id} className='stackHorizontal gap-20 items-center'>
              <div style={{ display: 'inlineFlex' }}>
                <span>{ dependentTask.notice }</span>{' '}
                { dependentTask.reservation && (
                  <span>
                    Your { dependentTask.appointment_type_display_name } is currently scheduled for{' '}
                    <strong>{ format.date(dependentTask.reservation?.start_time,'dateTime') }</strong>.
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      }
      actions={taskDetails?.dependent_building_tasks?.map(dependentTask => (
        activeMoveStep?.move_tasks?.find(task => task.id === dependentTask?.building_move_task_id)?.status !== 'locked' && (
          <Link to={`${origin}/tasks/${dependentTask.building_move_task_id}`} key={dependentTask.building_move_task_id}>
            <Button
              color='tertiary'
              text={dependentTask?.reservation ? 'Review' : dependentTask.title}
              icon={!dependentTask?.reservation && dependentTask.icon && { library:'stockholm', symbol: dependentTask.icon }}
            />
          </Link>
        )
      ))}
    />
  );
};
