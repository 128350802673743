import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { useNotify, format, useModal, useMobile } from '@moved/services';
import { Button, Card, Icon, useDragAndDropItem, PopoverMenu, Popover } from '@moved/ui';

import { DueDateInput} from '../types/common/DueDateInput';
import { ReminderInput } from '../types/common/ReminderInput';
import { ReminderSelectModal } from '../types/common/ReminderSelectModal';
import { DateSelectPopover } from '../types/common/DateSelectPopover';

import { deleteTask } from '../actions/deleteTask';
import { updateTask } from '../actions/updateTask';
import { getTaskList } from '../actions/getTaskList';

import { useOpenTaskDetails } from '../hooks/useOpenTaskDetails';

import CSS from './styles/TaskListItem.module.scss';

export const TaskListItem = ({ taskSummary, taskListId, className }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const modal = useModal();
  const isMobile = useMobile();
  const [pending, setPending] = useState();
  const { dragHandleProps, isDragging } = useDragAndDropItem();

  const openDetails = useOpenTaskDetails(taskSummary.id, taskListId, taskSummary.is_new);

  const toggleComplete = (e) => {
    e.stopPropagation(); // needed to avoid triggering the whole card's onClick
    if(pending) return;
    setPending(true);
    dispatch(updateTask(taskSummary.id,{status: taskSummary.status === 'todo' ? 'complete' : 'todo'}))
      // refresh the task list because ^ does not
      .then(() => dispatch(getTaskList(taskListId)))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  };

  const handleDeleteTask = (e) => {
    e.stopPropagation(); // needed to avoid triggering the whole card's onClick
    if(pending) return;
    setPending(true);
    dispatch(deleteTask(taskListId,taskSummary.id))
      .catch(err => notify.error(format.error(err)))
      .finally(() => setPending(false));
  }

  const openReminderModal = (e) => {
    e.stopPropagation(); // needed to avoid triggering the whole card's onClick
    modal.open(
      <ReminderSelectModal
        taskDetails={taskSummary}
        taskListId={taskListId}
      />
    );
  };

  const updateDueDate = (date) => {
    const formattedDueDate = date ? moment(date).format('YYYY-MM-DD') : '';
    dispatch(updateTask(taskSummary.id, { due_date: formattedDueDate }))
      .then(() => dispatch(getTaskList(taskListId))) // refresh the task list
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Card
      className={classNames(CSS.task,'stackHorizontal',{ [CSS.dragging]: isDragging }, className)}
      onClick={openDetails}
    >
      <div className={classNames(CSS.taskTitle,'stackHorizontal gap-8')}>
        { taskSummary?.is_new && (
          <div className={CSS.newTaskIndicator} />
        )}
        <div className={CSS.dragHandle} {...dragHandleProps} onClick={e => e.stopPropagation()}>
          <Icon symbol='Drag' library='general' />
        </div>
        <Icon className={CSS.icon} symbol={taskSummary?.icon?.symbol} library={taskSummary?.icon?.library} />
        <div className='headingS contentPrimary'>{taskSummary?.title}</div>
      </div>

      <div className={classNames(CSS.taskActions,'stackHorizontal items-center gap-4')}>

        <div
          onClick={e => e.stopPropagation()}
          className={classNames({[CSS.visible]: taskSummary?.reminder })}
        >
          <ReminderInput
            taskDetails={taskSummary}
            taskListId={taskListId}
            label='Reminder'
            className={CSS.actionButton}
          />
        </div>

        <div
          onClick={e => e.stopPropagation()}
          className={classNames({[CSS.visible]: taskSummary?.due_date })}
        >
          <DueDateInput
            taskDetails={taskSummary}
            taskListId={taskListId}
            label='Due date'
            className={CSS.actionButton}
          />
        </div>
      </div>

      <div className={CSS.completeToggle}>
        { taskSummary.status === 'todo' && (
          <Button
            size='small'
            text='Mark complete'
            color='tertiary'
            className={CSS.markComplete}
            onClick={toggleComplete}
            disabled={pending}
          />
        )}
        { taskSummary.status === 'complete' && (
          <>
            <Button
              size='small'
              text='Completed'
              color='secondary'
              icon={{ symbol: 'Done', library: 'code' }}
              className={CSS.isComplete}
            />
            <Button
              size='small'
              text='Mark incomplete'
              color='tertiary'
              showWarning={true}
              className={CSS.markIncomplete}
              onClick={toggleComplete}
              disabled={pending}
            />
          </>
        )}
      </div>

      <div className={classNames(CSS.taskButtons,'stackHorizontal gap-16')}>
        <PopoverMenu>
          <PopoverMenu.Trigger onClick={e => e.stopPropagation()} className={CSS.taskButton}>
            <Icon library='general' symbol='Other#2' color='inherit' />
          </PopoverMenu.Trigger>
          <PopoverMenu.Content mountToBody onClick={e => e.stopPropagation()}>
            <PopoverMenu.Item
              label={taskSummary.status === 'todo' ? 'Mark complete' : 'Mark incomplete'}
              icon={{ library: 'code', symbol: 'Done-circle' }}
              onClick={toggleComplete}
            />
            <PopoverMenu.Item
              label='View details'
              icon={{ library: 'general', symbol: 'Visible' }}
              onClick={e => e.stopPropagation() ?? openDetails()}
            />
            <PopoverMenu.Divider />
            <PopoverMenu.Item
              label={taskSummary?.reminder ? 'Edit reminder' : 'Remind me...'}
              icon={{ library: 'home', symbol: 'Alarm-clock' }}
              onClick={openReminderModal}
            />
            <Popover placement={isMobile ? 'top' : 'left'}>
              <Popover.Trigger onClick={e => e.stopPropagation()}>
                <PopoverMenu.Item
                  label={taskSummary?.due_date ? 'Edit due date' : 'Add due date'}
                  icon={{ library: 'code', symbol: 'Date' }}
                />
              </Popover.Trigger>
              <Popover.Content>
                <DateSelectPopover
                  title={taskSummary?.due_date ? 'Edit due date' : 'Add due date'}
                  initialDate={taskSummary.due_date}
                  onSelect={updateDueDate}
                />
              </Popover.Content>
            </Popover>
            <PopoverMenu.Divider />
            <PopoverMenu.Item
              label='Delete task'
              icon={{ library: 'general', symbol: 'Trash' }}
              onClick={handleDeleteTask}
              className={CSS.delete}
            />
          </PopoverMenu.Content>
        </PopoverMenu>
        <div className={classNames(CSS.taskButton,CSS.taskArrow)}>
          <Icon library='navigation' symbol='Chevron-right' color='inherit' />
        </div>
      </div>
    </Card>
  );
};
