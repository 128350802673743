import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_PET_INSURANCE_CREATE_PET',
  request: (id,data={}) => request.post(`/residents/personal-tasks/${id}/pets`,data)
    .then(r => r.data.data.pet),
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      availablePets: {
        ...state.availablePets,
        [id]: {
          ...state.availablePets?.[id],
          pets: [
            ...(state.availablePets?.[id]?.pets || []),
            {
              ...response,
              is_selected: true,
            },
          ],
        },
      },
    }),
  }
};

export const {
  reducer,
  action: createPet,
  usePending: useCreatePetPending,
} = generate.redux(creator);

export const initialState = {};
