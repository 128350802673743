import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_PURCHASE_PET_INSURANCE_GET_AVAILABLE_PETS',
  request: (id) => request.get(`/residents/personal-tasks/${id}/eligible-pets`).then(r => r.data.data),
  selector: (state, id) => state?.availablePets?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => ({
      ...state,
      availablePets: {
        ...state.availablePets,
        [id]: response,
      },
    }),
  }
};

export const {
  reducer,
  action: getAvailablePets,
  useResource: useAvailablePets,
  usePending: useAvailablePetsPending,
} = generate.redux(creator);

export const initialState = {
  availablePets: {},
};
