import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useNotify, format } from '@moved/services';
import {
  Form,
  FormNumber,
  FormSlideToggle,
  FormText,
  FormTypeAhead,
  Button,
  BouncingDots,
} from '@moved/ui';

import { Screen, ScreenLoading } from '../../../tasks/types/shared';
import { getPetTypes, usePetTypes } from '../../../tasks/types/pet-registration/actions/getPetTypes';

import { createPet } from './actions/createPet';
import { getPetBreeds, usePetBreeds } from './actions/getPetBreeds';
import { petAgeLimits } from './helpers/petAgeLimits';

export const AddNewPetScreen = ({ screen, personalTask }) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const [activePetType, setActivePetType] = useState('dog');
  const [loading, setLoading] = useState(true);

  const petTypes = usePetTypes();
  const petBreeds = usePetBreeds();
  const supportedPetOptions = (petTypes ?? [])
    ?.filter(({ label }) => ['dog','cat'].includes(label))
    ?.map(({ id, name }) => ({ value: id, label: name }));

  useEffect(() => {
    Promise.all([
      dispatch(getPetTypes()),
      dispatch(getPetBreeds()),
    ])
      .then(() => setLoading(false))
      .catch(error => notify.error(format.error(error)));
  }, [dispatch, notify]);

  const submit = (data) => {
    dispatch(createPet(personalTask.id, data))
      .catch(error => notify.error(format.error(error)));
  };

  if(loading) return  (
    <ScreenLoading message={<span>Loading pet options<BouncingDots /></span>} />
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    pet_type_id: Yup.number().required('Required'),
    breed: Yup.string().required('Required').nullable(),
    age_years: Yup.number().required('Required').when('pet_type_id',
      (pet_type_id, schema) => {
        const petType = petTypes?.find(({ id }) => id === pet_type_id);
        const maxAge = petAgeLimits[petType?.label] - 1;
        return schema.max(maxAge, `Maximum insurable ${petType?.label} age is ${maxAge} years`);
      },
    ),
    age_months: Yup.number().required('Required').max(11, 'Must be between 0 and 11').min(0, 'Must be between 0 and 11'),
    weight_in_lbs: Yup.number().required('Required'),
    color: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
  });

  return (
    <Screen screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <Form
          name='newPet'
          onSubmit={submit}
          validation={validationSchema}
          className='stackVertical gap-20'
        >
          <FormText
            name='name'
            label='Pet name'
          />

          <div className='stackHorizontal gap-24 stackVertical--mobile'>
            <FormSlideToggle
              name='pet_type_id'
              label='Pet type'
              size='large'
              options={supportedPetOptions}
              value={supportedPetOptions?.at(0)?.value}
              onChange={({pet_type_id}) => setActivePetType(petTypes?.find(({ id }) => id === pet_type_id).label)}
              className='flex-auto width-half width-full--mobile'
            />
            <FormSlideToggle
              name='gender'
              label='Gender'
              size='large'
              options={[
                { value: 'female', label: 'Female' },
                { value: 'male', label: 'Male' },
              ]}
              value='female'
              className='flex-auto width-half width-full--mobile'
            />
          </div>

          <div className='stackHorizontal gap-24 stackVertical--mobile'>
            <FormTypeAhead
              name='breed'
              label='Breed'
              options={petBreeds?.[activePetType]?.map(breed => ({
                label: breed,
                value: breed,
              }))}
              hint='Start typing to search'
              className='flex-auto width-half width-full--mobile'
            />
            <div className='stackHorizontal gap-24 flex-auto width-half width-full--mobile'>
              <FormNumber
                name='age_years'
                label='Age (years)'
                type='number'
                className='flex-auto width-half'
                min={0}
              />
              <FormNumber
                name='age_months'
                label='Months'
                type='number'
                className='flex-auto width-half'
                min={0}
                max={11}
              />
            </div>
          </div>

          <div className='stackHorizontal gap-24  stackVertical--mobile'>
            <FormNumber
              name='weight_in_lbs'
              label='Weight (lbs)'
              type='number'
              className='flex-auto'
              min={0}
              allowDecimal
            />
            <FormText
              name='color'
              label='Color'
              hint='Beige, black, white, etc.'
              className='flex-auto'
            />
          </div>

        </Form>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          form='newPet'
          // disabled={pending}
          className='width-full'
        />
      </Screen.Actions>
    </Screen>
  );
};
