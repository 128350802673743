import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { Button, Icon } from '@moved/ui';

import {
  Screen,
  // ScreenLoading,
} from '../../../tasks/types/shared';

import { useAvailablePets } from './actions/getAvailablePets';
import { linkPet } from './actions/linkPet';

import { PetCard } from './PetCard';

import CSS from './AvailablePetsScreen.module.scss';

export const AvailablePetsScreen = ({ screen, personalTask, setCreateNewPet }) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const {
    pets: availablePets = [],
   } = useAvailablePets(personalTask?.id) ?? {};

  const [selectedPetId, setSelectedPetId] = useState();

  const submit = () => {
    if(selectedPetId === false) return setCreateNewPet(true);
    dispatch(linkPet(personalTask.id, selectedPetId))
      .catch(error => notify.error(format.error(error)));
  }

  return (
    <Screen screen={screen}>
      <Screen.Title />
      <Screen.Content>
        <div className='stackHorizontal gap-20 justify-center flex-wrap'>
          { availablePets.map(pet => (
            <PetCard
              key={pet.id}
              pet={pet}
              isSelected={selectedPetId === pet.id}
              onSelect={() => setSelectedPetId(pet.id)}
            />
          ))}
          <div
            className={classNames(CSS.newPetCard,{[CSS.selected]: selectedPetId === false})}
            onClick={() => setSelectedPetId(false)}
          >
            <Icon
              library='code'
              symbol='Radio-on'
              className={CSS.radio}
            />
            <Button
              icon={{ library: 'navigation', symbol: 'Plus' }}
              color='secondary'
            />
            <div className='stackVertical gap-4 items-center'>
              <div className='labelM contentPrimary'>
                Add a pet
              </div>
              <div className='labelS contentSecondary'>
                We only insure dogs and cats
              </div>
            </div>
          </div>
        </div>
      </Screen.Content>
      <Screen.Actions>
        <Button
          text='Next'
          size='large'
          onClick={submit}
          className='width-full'
          disabled={selectedPetId == null}
        />
      </Screen.Actions>
    </Screen>
  );
};
