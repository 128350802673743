import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { BouncingDots } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { getMove } from '../../../moves/actions/getMove';
import { getTaskList } from '../../actions/getTaskList';

import {
  Screen,
  ScreenLoading,
} from '../../../tasks/types/shared';

import { createInsurancePurchaseIntent } from '../purchaseRentersInsurance/actions/create';
import { submitInsurancePurchaseIntent } from '../purchaseRentersInsurance/actions/submit';

import CSS from './ReinInsuranceScreen.module.scss';

export const ReinInsuranceScreen = ({ screen, personalTask, purchaseIntent, move }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [pending, setPending] = useState();

  const submit = useCallback(data => {
    if(pending) return;
    setPending(true);
    dispatch(submitInsurancePurchaseIntent(purchaseIntent.id, data))
      .then(() => Promise.all([
        dispatch(getMove(move.id)), // refresh the move to find the new insurance_purchase
        dispatch(getTaskList(move.personal_task_list_id)), // refresh the task list (task should be completed)
      ]))
      .catch(error => notify.error(format.error(error)));
  }, [purchaseIntent, move, pending, notify, dispatch]);

  useEffect(() => {
    if(purchaseIntent) return;
    dispatch(createInsurancePurchaseIntent(personalTask.id))
      .catch(err => notify.error(format.error(err)));
  }, [personalTask, purchaseIntent, dispatch, notify]);

  useEffect(() => {
    const ReinListenerHandler = ({ origin, data }) => {
      if(!origin.includes('rein.ai')) return; // only handle REIN messages
      if(data?.event_name === 'Policy Issued') submit({
        rein_reference_id: data?.customerJourneyId,
        policy_number: data?.event_value,
        external_customer_id: data?.referenceId,
      });
    };
    window.addEventListener('message', ReinListenerHandler);
    return () => window.removeEventListener('message', ReinListenerHandler);
  }, [submit]);

  if(!purchaseIntent) return (
    <ScreenLoading message={
      <span>
        Retrieving personalized quote for your pet<BouncingDots />
      </span>
    }/>
  );
  if(pending) return <ScreenLoading />;
  return (
    <Screen screen={screen} className={CSS.wrapper}>
      <Screen.Content className={CSS.container}>
        <iframe
          src={`${process.env.REACT_APP_REIN_PET_DOMAIN}/?client_id=${purchaseIntent?.rein_reference_id}`}
          height='100%'
          width='100%'
          style={{border:'none', flex: '1 1 auto'}}
          title='REIN Pet Insurance Purchase Flow'
        />
      </Screen.Content>
    </Screen>
  );
};
